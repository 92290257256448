import './App.css';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import React, { useState } from 'react';

function App() {
  const [email, setEmail] = useState('');

  function navigationClick(event) {
    console.log(event);
    if (event === 'Privacy Policy') {
      window.location = '/kub-systems-privacy-policy.pdf';
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={process.env.PUBLIC_URL + '/kub-systems-logo.jpeg'} className="App-logo" alt="logo" />
        <Typography variant="h2">Kub Systems</Typography>
        <Typography variant='h5' sx={{ paddingLeft: '20%', paddingRight: '20%' }}>A leading cloud services provider based in Ashburn, VA.</Typography>
        <Typography variant='subtitle1' sx={{ paddingLeft: '20%', paddingRight: '20%' }}>Secure your data in the cloud. Go digital. Personalize every customer experience. Make real-time, intelligent business decisions.</Typography>
        <Grid container spacing={2} justifyContent='center' alignItems='center' mt={3} mb={40}>
          <Grid item>
            <TextField label="Email address" variant="outlined" size='small' onChange={(e) => setEmail(e.target.value)} />
          </Grid>
          <Grid item>
            <Button variant='contained' sx={{ backgroundColor: '#414143', color: '#f2f2f2' }}>Get in touch</Button>
          </Grid>
        </Grid>
        
        <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
          <BottomNavigation showLabels onChange={(e) => navigationClick(e.target.innerText)}>
            <BottomNavigationAction label="Charged in USD" />
            <BottomNavigationAction label="Privacy Policy" />
            <BottomNavigationAction label="erik@kubsystems.com" />
          </BottomNavigation>
        </Box>

      </header>

    </div >
  );
}

export default App;
